import React from "react";
import VisibilitySensor from "../visibilitySensor";
import { SwiperBeerCan,  SwiperBeerCanSlide } from "../swiperBeerCan"

// import IconBeer from "../../images/svg/lattina.svg";

import "./beer-why-can.scss"

import contents from "./beerWhyCan.yml";

const BeerWhyCan = (props) => {

    const {
      mainTitle,
      cards
    } = contents;

  return (

    <div className="why-can__container">
      <VisibilitySensor>
        {({ isVisible }) => (
          <div className={`why-can__wrapper why-can__wrapper--no-height ${isVisible ? 'slideUp sensorEnter' : 'slideUp'}`}>
            <h2 className="why-can__title">
              {mainTitle}
            </h2>
            {cards && cards.length &&
                <SwiperBeerCan>
                  {cards.map((card, i) => {
                    return (
                        <SwiperBeerCanSlide
                          key={i}
                          // srcImage={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${card.icon.fullpath}`}
                          srcImage={card.icon.fullpath}
                          title={card.title}
                          description={card.description}
                        />
                    );
                  })}
                </SwiperBeerCan>
            }
          </div>
        )}
      </VisibilitySensor>
    </div>

  );
};

export default BeerWhyCan;
