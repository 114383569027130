import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";

import 'swiper/css/swiper.css';

import "./swiper-beer-can.scss";

export const SwiperBeerCanSlide = (props) => {
  return (
    <>
      <div className="swiper-slide">
        <div className="slide-can">
          <img
            className="slide-can__img"
            alt=""
            src={props.srcImage}
          />
          <p
            className="slide-can__title"
          >
            {props.title}
          </p>
          <p
            className="slide-can__description"
          >
            {props.description}
          </p>
        </div>
      </div>
    </>
  )
}

export const SwiperBeerCan = (props) => {
  const [swiper, initSwiper] = useState(null);
  // ENABLE SWIPER: when isMobile is true we enable the swiper params
  const [isMobile, setMobile] = useState(false);
  const updateMedia = () => {
    setMobile(window.innerWidth <= 768);
    if (!isMobile && swiper != null) {
      swiper.destroy(false,true);
    }
  };

  useEffect(()=>{
    updateMedia();
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const params = {
    slidesPerView: 1.5,
    // spaceBetween: '-15%',
    spaceBetween: 0,
    a11y: {
      prevSlideMessage: 'Slide precedente',
      nextSlideMessage: 'Slide successiva',
      firstSlideMessage: 'Questa è la prima slide',
      lastSlideMessage: 'Questa è l’ultima slide'
    },
    speed: 1000,
    watchOverflow: true,
    watchSlidesVisibility: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  };

  return (
      <div className="cards__wrapper">
          <Swiper containerClass="swiper-container swiper-container--cans" {...params} getSwiper={initSwiper}>

            {props.children}

          </Swiper>
      </div>
  );
};
