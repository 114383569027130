import React from 'react';
import PropTypes from "prop-types";

const ProductsRangeMicrodata = ({range}) => {

  // @todo fix product link
  const itemListElements = range.map((item,i) => {
    return (
      {
        "@type":"ListItem",
        "position": (i+1),
        "url": `./${item.fullpath}`
      }
    )
  });

  const structuredData =
    {
      "@context":"https://schema.org",
      "@type":"ItemList",
      "itemListElement": itemListElements
    };

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  )
};

ProductsRangeMicrodata.propTypes = {
  range: PropTypes.array.isRequired
};

export default ProductsRangeMicrodata;
