import React from "react";
import PageTransition from '../pageTransition';
import PropTypes from "prop-types";
import SEO from "../seo";
import ProductsRangeMicrodata from "../productsRangeMicrodata";
// import ArrowDown from "../arrowDown";
import BeerWhyCan from "../beerWhyCan";
import CONST from '../../config/constants';
import {compareDisplayOrderDesc} from "../../helpers";

import "./beers-range-template.scss"

const BeersRangeTemplate = (props) => {

  const {
    beers
  } = props.pageContext;

  beers.sort(compareDisplayOrderDesc);

  const currentRange = CONST.PRODUCT_RANGES.find(range => {
    return beers && beers.length && beers[0].productCategory && range.id === beers[0].productCategory;
  });

  const width = 100/beers.length;

  return (

    <main>

      <SEO
        title={currentRange ? currentRange.seoTitle || '' : ''}
        description={currentRange ? currentRange.seoDescription || '' : ''}
      />

      {beers && beers.length &&
        <>
          <ProductsRangeMicrodata
            range={beers}
          />

          <h1 className="range__title">{currentRange.label}</h1>

          <div className="range__container">
            <ul className="range__wrapper">
              {
                beers.map((beer, i) => {

                  const {
                    categorySlug,
                    slug,
                    productName,
                    productImage,
                    productHoverImage,
                    productHoverTextColor
                  } = beer;

                  return (
                    <li
                      key={i}
                      className="range__beer"
                      style={{
                        color: `${productHoverTextColor}`,
                        width: `${width}%`
                      }}
                    >
                      <PageTransition to={`${CONST.ROUTES.BEERS}/${categorySlug}/${slug}`} className="beer__wrapper">
                        <h2 className="beer__title" data-shadow={productName}>
                          {productName}
                        </h2>
                        <div className="beer__image-wrapper">
                          { productImage && <img alt={productName} className="beer__image-product" src={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${productImage.fullpath}`}/> }
                          { productHoverImage && <img alt={productName} className="beer__image-hover" src={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${productHoverImage.fullpath}`}/> }
                        </div>
                      </PageTransition>
                    </li>
                  )
                })
              }
            </ul>
          </div>

          {currentRange.slug === 'in-lattina' &&
              <>
                  {/*<ArrowDown />*/}
                  <BeerWhyCan />
              </>
          }

        </>
      }
    </main>

  );
};



BeersRangeTemplate.propTypes = {
  beers: PropTypes.array.isRequired
};

BeersRangeTemplate.defaultProps = {
  beers: []
};

export default BeersRangeTemplate;
